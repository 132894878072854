import { useAppSelector } from '@frontend/common';
import { MqttBroker } from '@frontend/pub-sub';
import { navigationStore } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { SlotStatusProps } from './slot-status.component';

interface ViewProps {
    view: View;
}

const useSlotStatus = (props: SlotStatusProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [view, changeView] = useState<View>(View.OPENING);

    const callback = (message: string, data: any) => {
        if (message === 'inform') {
            if (data.data.trigger) {
                if (data.data.trigger === 'iot_slot_opened') changeView(View.OPENED);
                if (data.data.trigger === 'iot_slot_closed') {
                    changeView(View.CLOSED);
                    props.closedCallBack && props.closedCallBack();
                }
            }
        }
    };

    useEffect(() => {
        if (navigationState.iot) {
            MqttBroker.getInstance().subscribe('processing_slot_updates_iot', 'iots/' + navigationState.iot.id + '/' + 'iot', callback);
        }

        return () => {
            if (navigationState.iot) {
                MqttBroker.getInstance().unSubscribe('processing_slot_updates_iot', 'iots/' + navigationState.iot.id + '/' + 'iot');
            }
        };
    }, []);

    return {
        view
    };
};

export default useSlotStatus;

export enum View {
    OPENING = 'opening',
    OPENED = 'opened',
    CLOSED = 'closed'
}
