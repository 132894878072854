import React, { forwardRef, useEffect } from 'react';

export const Checkbox = forwardRef<HTMLInputElement, { indeterminate?: boolean | undefined }>(({ indeterminate, ...rest }, ref) => {
    const resolvedRef = ref;

    useEffect(() => {
        if (resolvedRef && resolvedRef != null && typeof resolvedRef !== 'function' && resolvedRef.current && indeterminate != undefined)
            resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <div className='form-check text-lg ms-2 my-0 py-0'>
            <input
                className='form-check-input my-0 py-0'
                type='checkbox'
                ref={resolvedRef}
                {...rest}
            />
        </div>
    );
});

Checkbox.displayName = 'Search';
