import { useMemo } from 'react';

import { LayoutItemProps } from './layout-item.component';

interface ViewProps {
    style: React.CSSProperties;
    label?: string;
    onClick?: () => void;
    divClassName: string;
    spanClassName: string;
}

export const layoutItemDivClass = 'spotlayout-item spotlayout-item-secondary align-items-center justify-content-center w-100 h-100';
export const layoutItemSpanClass = '';

const useLayoutItem = (props: LayoutItemProps): ViewProps => {
    const divClassName = useMemo(() => {
        if (props.styles?.div) {
            if (typeof props.styles?.div === 'function') return props.styles?.div(props.slotRendering);
            else return props.styles?.div;
        } else return layoutItemDivClass;
    }, [props.styles, props.styles?.div]);
    const spanClassName = useMemo(() => {
        if (props.styles?.span) {
            if (typeof props.styles?.span === 'function') return props.styles?.span(props.slotRendering);
            else return props.styles?.span;
        } else return layoutItemSpanClass;
    }, [props.styles, props.styles?.span]);

    const label = typeof props.label === 'function' ? props.label(props.slotRendering) : props.label;
    const style: React.CSSProperties = {
        position: 'absolute',
        left: `${(100 * props.slotRendering.location.x) / props.fullWidth}%`,
        right: `${100 - (100 * (props.slotRendering.location.x + props.slotRendering.dimension.width)) / props.fullWidth}%`,
        bottom: `${(100 * props.slotRendering.location.y) / props.fullHeight}%`,
        top: `${100 - (100 * (props.slotRendering.location.y + props.slotRendering.dimension.height)) / props.fullHeight}%`
    };

    const onClick = () => {
        props.onClick && props.onClick(props.slotRendering);
    };

    return {
        style,
        label,
        onClick,
        divClassName,
        spanClassName
    };
};

export default useLayoutItem;
