import { BadgeProps, useBadgeRepository } from '@frontend/repository';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IconType } from 'react-icons';

export interface ObjectBadgeProps<T> {
    id?: string;
    className?: string;
    callback?: (object: T) => void;
    navigateString?: (object: T) => string;
    icon?: IconType;
}

export const ObjectBadge = <T,>(props: BadgeProps<T>) => {
    const repository = useBadgeRepository<T>(props);
    const Icon = props.icon || (props.overWriteIcon ? props.overWriteIcon(repository.object as T) : repository.icon);
    return (
        <OverlayTrigger
                    overlay={<Tooltip id={'tooltip'}>{repository.displayName}</Tooltip>}
                    placement='auto'
                    delay={{ show: 100, hide: 100 }}>
            <span
                id={'badge-' + props.id}
                className={props.className ? props.className : `badge badge-secondary ${repository.disabled ? 'disabled' : 'cursor-pointer'} m-1`}
                onClick={(e) =>
                    !repository.disabled &&
                    repository.isPlatform &&
                    props.navigateString &&
                    repository.object &&
                    repository.onClick(e, props.navigateString(repository.object))
                }>
                <Icon className='me-2' />
                
                {repository.displayName ? (
                    <span>{repository.displayName}</span>
                ) : (
                    <span className='placeholder-glow'>
                        <span className='placeholder'>loading</span>
                    </span>
                )}
            </span>
        </OverlayTrigger>
    );
};
