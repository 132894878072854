import { Module } from '@frontend/module/types';
import { SlotRendering } from '@frontend/slot/types';
import React from 'react';

import LayoutItem from '../layout-item/layout-item.component';
import useModuleLayoutItem from './module-layout-item.controller';

const ID = 'module-layout-item';
export interface ModuleLayoutItemProps {
    id?: string;

    module: Module;
    slotRendering: SlotRendering[];
    fullWidth: number;
    fullHeight: number;

    label?: string | ((module: Module, slotRendering: SlotRendering[]) => string);
    styles?: {
        div?: string | ((module: Module, slotRendering: SlotRendering[]) => string);
        span?: string | ((module: Module, slotRendering: SlotRendering[]) => string);
    };

    slotRenderingProps?: Omit<React.ComponentProps<typeof LayoutItem>, 'id' | 'fullWidth' | 'fullHeight' | 'slotRendering'>;
}

const ModuleLayoutItem = (props: ModuleLayoutItemProps) => {
    const viewProps = useModuleLayoutItem(props);
    return (
        <div
            style={viewProps.style}
            id={ID + '-' + props.id + '-' + props.module.id}
            className={viewProps.divClassName}>
            <span
                className={viewProps.spanClassName}
                style={{ position: 'absolute', top: 0, left: 0, zIndex: 10, textShadow: '1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000' }}>
                {viewProps.label}
            </span>
            {props.slotRendering.map((slotRendering) => {
                return (
                    <LayoutItem
                        id={props.id + '-' + slotRendering.id}
                        key={slotRendering.id}
                        fullWidth={props.module.dimension.width}
                        fullHeight={props.module.dimension.height}
                        slotRendering={slotRendering}
                        {...props.slotRenderingProps}
                    />
                );
            })}
        </div>
    );
};

export default ModuleLayoutItem;
