import { EntityType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Buttons = {
    CANCEL: (
        <FormattedMessage
            id='Buttons.CANCEL'
            description='Cancel text on a button'
            defaultMessage='Cancel'
        />
    ),
    DISMISS: (
        <FormattedMessage
            id='Buttons.DISMISS'
            description='Dismiss text on a button'
            defaultMessage='Dismiss'
        />
    ),
    BACK: (
        <FormattedMessage
            id='Buttons.BACK'
            description='Back text on a button'
            defaultMessage='Back'
        />
    ),
    HOME: (
        <FormattedMessage
            id='Buttons.HOME'
            description='Home text on a button'
            defaultMessage='Home'
        />
    ),
    LOGOUT: (
        <FormattedMessage
            id='Buttons.LOGOUT'
            description='Logout text on a button'
            defaultMessage='Logout'
        />
    ),
    SUBMIT: (
        <FormattedMessage
            id='Buttons.SUBMIT'
            description='Submit text on a button'
            defaultMessage='Submit'
        />
    ),
    DELETE: (
        <FormattedMessage
            id='Buttons.DELETE'
            description='Delete text on a button'
            defaultMessage='Delete'
        />
    ),
    UPDATE: (
        <FormattedMessage
            id='Buttons.UPDATE'
            description='Update text on a button'
            defaultMessage='Update'
        />
    ),
    OPEN: (
        <FormattedMessage
            id='Buttons.OPEN'
            description='Open text on a button'
            defaultMessage='Open'
        />
    ),
    CLOSE: (
        <FormattedMessage
            id='Buttons.CLOSE'
            description='Close text on a button'
            defaultMessage='Close'
        />
    ),
    CHANGE_STATE: (
        <FormattedMessage
            id='Buttons.CHANGE_STATE'
            description='Change state text on a button'
            defaultMessage='Change state'
        />
    ),
    ADD: Add,
    ADD_STOCK: (
        <FormattedMessage
            id='Buttons.ADD_STOCK'
            description='Add stock text on a button'
            defaultMessage='Add stock'
        />
    ),
    CONFIRM: (
        <FormattedMessage
            id='Buttons.CONFIRM'
            description='Confirm text on a button'
            defaultMessage='Confirm'
        />
    ),
    IMPORT: (
        <FormattedMessage
            id='Buttons.IMPORT'
            description='Import text on a button'
            defaultMessage='Import'
        />
    ),
    EXPORT: (
        <FormattedMessage
            id='Buttons.EXPORT'
            description='Export text on a button'
            defaultMessage='Export'
        />
    ),
    UPLOAD: Upload,
    LINK: (
        <FormattedMessage
            id='Buttons.LINK'
            description='Link text on a button'
            defaultMessage='Link'
        />
    ),
    UNLINK: (
        <FormattedMessage
            id='Buttons.UNLINK'
            description='Unlink text on a button'
            defaultMessage='Unlink'
        />
    ),
    ATTACH: Attach,
    CHANGE: Change,
    NEXT: (
        <FormattedMessage
            id='Buttons.NEXT'
            description='Next text on a button'
            defaultMessage='Next'
        />
    ),
    PREVIOUS: (
        <FormattedMessage
            id='Buttons.PREVIOUS'
            description='Previous text on a button'
            defaultMessage='Previous'
        />
    ),
    VIEW: (
        <FormattedMessage
            id='Buttons.VIEW'
            description='View text on a button'
            defaultMessage='View'
        />
    ),
    DROP_OFF: (
        <FormattedMessage
            id='Buttons.DROP_OFF'
            description='Drop off text on a button'
            defaultMessage='DropOff'
        />
    ),
    PICK_UP: (
        <FormattedMessage
            id='Buttons.PICK_UP'
            description='Pick up text on a button'
            defaultMessage='PickUp'
        />
    ),
    SEED: (
        <FormattedMessage
            id='Buttons.SEED'
            description='Seed text on a button'
            defaultMessage='Seed'
        />
    ),
    COPY: (
        <FormattedMessage
            id='Buttons.COPY'
            description='Copy text on a button'
            defaultMessage='Copy'
        />
    ),
    EXIT: (
        <FormattedMessage
            id='Buttons.EXIT'
            description='Exit text on a button'
            defaultMessage='Exit'
        />
    ),
    FORMATTED_UNLINK: FormattedUnlink,
    CLEAR: (
        <FormattedMessage
            id='Buttons.CLEAR'
            description='Clear text on a button'
            defaultMessage='Clear'
        />
    ),
    CONTINUE: (
        <FormattedMessage
            id='Buttons.CONTINUE'
            description='Continue text on a button'
            defaultMessage='Continue'
        />
    ),
    SEARCH: (
        <FormattedMessage
            id='Buttons.SEARCH'
            description='Search text on a button'
            defaultMessage='Search'
        />
    ),
    SCAN: (
        <FormattedMessage
            id='Buttons.SCAN'
            description='Scan text on a button'
            defaultMessage='Scan'
        />
    ),
    PIN: (
        <FormattedMessage
            id='Buttons.PIN'
            description='Pin text on a button'
            defaultMessage='Pin'
        />
    )
};

function Add(text?: React.ReactNode): React.ReactNode {
    if (text)
        return (
            <FormattedMessage
                id='Buttons.ADD.Something'
                description='Add something text on a button'
                defaultMessage='Add {text}'
                values={{ text }}
            />
        );
    return (
        <FormattedMessage
            id='Buttons.ADD'
            description='Add text on a button'
            defaultMessage='Add'
        />
    );
}

function Upload(type?: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='Buttons.UPLOAD'
            description='Upload text on a button'
            defaultMessage='Upload {text}'
            values={{ text: type }}
        />
    );
}

function Attach(type: EntityType): React.ReactNode {
    return (
        <FormattedMessage
            id='Buttons.ATTACH'
            description='Attach text on a button'
            defaultMessage='Attach {type}'
            values={{ type }}
        />
    );
}

function Change(text: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='Buttons.CHANGE'
            description='Change text on a button'
            defaultMessage='Change {text}'
            values={{ text }}
        />
    );
}

function FormattedUnlink(count: number, singular?: React.ReactNode, plural?: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='buttons.unlinkMessage'
            defaultMessage='Do you really want to unlink {count, plural, one {this {singular}} other {these {plural}}}?'
            values={{ count: count, singular: singular, plural: plural }}
        />
    );
}
