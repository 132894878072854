import { ClassType, EntityType, useAppSelector } from '@frontend/common';
import { ContactClient } from '@frontend/contact/api';
import { Contact } from '@frontend/contact/types';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PackageVariableClient } from '@frontend/package-variables/api';
import { PackageClient } from '@frontend/package/api';
import { Package, PackageType } from '@frontend/package/types';
import { SlotClient, SlotWorkflowClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { SpotClient } from '@frontend/spot/api';
import { navigationStore } from '@frontend/terminal/shared';
import { TransactionClient, TransactionWorkflowClient } from '@frontend/transaction/api';
import { TransactionStateName, TransactionType } from '@frontend/transaction/types';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { UserInterfaceProps } from '../types/user-interface';

interface ViewProps {
    changeSelectedContact: (contact: Contact) => void;
    selectedContact: Contact | null;
    slotList: Slot[];
    changeSlot: Dispatch<SetStateAction<string | null>>;
    slot: string | null;
    isDropping: boolean;
    dropPackage: Package | null;
    onDropOff: () => void;
    slotMap: Map<string, ClassType>;
    disabledSlots: Slot[];
}

const useDeliveryDropoff = (props: UserInterfaceProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const iot = navigationState.iot;
    const [selectedContact, changeSelectedContact] = useState<Contact | null>(null);
    const [sender, changeSender] = useState<Contact | null>(null);
    const [isDropping, changeIsDropping] = useState<boolean>(false);
    const [dropPackage, changeDropPackage] = useState<Package | null>(null);
    const [slot, changeSlot] = useState<string | null>(null);
    const [slotList, changeSlotList] = useState<Slot[]>([]);
    const [foundSlot, changeFoundSlot] = useState<Slot | null>(null);
    const [availableSlots, changeAvailableSlots] = useState<Slot[]>([]);
    const [disabledSlots, changeDisabledSlots] = useState<Slot[]>([]);
    const transactionWorkflow = props.userInterface.data.transactionWorkflowId;
    const dropPackageWorkflow = props.userInterface.data.dropPackageWorkflowId;
    const pickPackageWorkflow = props.userInterface.data.pickPackageWorkflowId;

    const slotMap = useMemo(() => {
        const map = new Map<string, ClassType>();
        availableSlots.map((slot) => map.set(slot.id, ClassType.PRIMARY));

        return map;
    }, [availableSlots]);

    useEffect(() => {
        if (navigationState.user) {
            ContactClient.fetchContacts({ user_id: navigationState.user.id }).then((res) => {
                if (res.results.length === 1) {
                    changeSender(res.results[0]);
                }
            });
        }
        if (iot) {
            IoTClient.patchIoT(iot.account_id, iot.id, { state: IoTStateName.IOT_DROP_OFF_PACKAGE });
            const spotId = navigationState.iot?.spot_id;
            if (!spotId) return;
            SpotClient.fetchSpot(iot.account_id, spotId as string).then((spot) => {
                SlotClient.fetchSlots({ spot_id: spot.id, index: '0', size: '100' }).then((slots) => {
                    SlotWorkflowClient.postAvailableSlots(
                        spot.account_id,
                        spot.id,
                        {
                            account_id: iot.account_id,
                            transaction_type: TransactionType.OPEN_DROP_OFF
                        },
                        { index: '0', size: '100', type: SlotType.DEFAULT }
                    ).then((availableSlots) => {
                        changeSlotList(slots.results);
                        const disabledSlots = slots.results.filter((slot) => !availableSlots.results.map((s) => s.id).includes(slot.id));
                        changeDisabledSlots(disabledSlots);
                        changeAvailableSlots(availableSlots.results);
                    });
                });
            });
        }
    }, []);

    useEffect(() => {
        if (slot) {
            const foundSlot = slotList.find((s) => s.id === slot);
            if (!foundSlot) return;
            changeFoundSlot(foundSlot);
        }
    }, [slot]);

    const onDropOff = () => {
        if (sender && selectedContact && slot && iot) {
            if (!foundSlot) return;
            TransactionClient.postTransaction(iot.account_id, {
                type: TransactionType.OPEN_DROP_OFF,
                workflow_id: transactionWorkflow,
                iot_id: iot.id,
                state: TransactionStateName.TRANSACTION_CREATED
            }).then((transaction) => {
                const promisePackages = [];
                promisePackages.push(
                    PackageClient.postPackage(transaction.account_id, transaction.id, {
                        type: PackageType.DROP_OFF,
                        workflow_id: dropPackageWorkflow,
                        spot_id: foundSlot?.spot_id,
                        module_id: foundSlot?.module_id,
                        slot_id: slot,
                        priority: 1
                    }).then((res) => {
                        changeDropPackage(res);
                    })
                );
                promisePackages.push(
                    PackageClient.postPackage(transaction.account_id, transaction.id, {
                        type: PackageType.PICK_UP,
                        workflow_id: pickPackageWorkflow,
                        spot_id: foundSlot?.spot_id,
                        module_id: foundSlot?.module_id,
                        slot_id: slot,
                        priority: 0
                    }).then((_package) => {
                        const variables = [];
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'from_entity_type',
                                value: EntityType.CONTACT,
                                type: 'entity_type',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'from_entity_id',
                                value: sender.id,
                                type: 'entity_id',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'to_entity_type',
                                value: EntityType.CONTACT,
                                type: 'entity_type',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        variables.push(
                            PackageVariableClient.postPackageVariable(_package.account_id, _package.transaction_id, _package.id, {
                                name: 'to_entity_id',
                                value: selectedContact.id,
                                type: 'entity_id',
                                workflow_id: pickPackageWorkflow
                            })
                        );
                        Promise.all(variables);
                    })
                );

                Promise.all(promisePackages).then(() => {
                    TransactionWorkflowClient.updateTransactionState(transaction.account_id, transaction.id, TransactionStateName.TRANSACTION_PROCESSING);
                    changeIsDropping(true);
                });
            });
        }
    };

    return {
        changeSelectedContact,
        selectedContact,
        changeSlot,
        slot,
        slotList,
        dropPackage,
        isDropping,
        onDropOff,
        disabledSlots,
        slotMap
    };
};

export default useDeliveryDropoff;
