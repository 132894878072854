import { ClassType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';


export enum TransactionStateName {
    TRANSACTION_CREATED = 'transaction_created',
    TRANSACTION_PROCESSING = 'transaction_processing',
    TRANSACTION_CANCELLED = 'transaction_cancelled',
    TRANSACTION_COMPLETED = 'transaction_completed',
    TRANSACTION_COMPLETED_WITH_FAILURES = 'transaction_completed_with_failures',
    TRANSACTION_FAILED = 'transaction_failed'
}

export class TransactionState {
    static readonly TRANSACTION_CREATED = new TransactionState(
        TransactionStateName.TRANSACTION_CREATED,
        (
            <FormattedMessage
                id='TransactionState.created'
                description='The displayed value for the TransactionState created'
                defaultMessage='Created'
            />
        )
    );
    static readonly TRANSACTION_PROCESSING = new TransactionState(
        TransactionStateName.TRANSACTION_PROCESSING,
        (
            <FormattedMessage
                id='TransactionState.processing'
                description='The displayed value for the TransactionState processing'
                defaultMessage='Processing'
            />
        )
    );
    static readonly TRANSACTION_CANCELLED = new TransactionState(
        TransactionStateName.TRANSACTION_CANCELLED,
        (
            <FormattedMessage
                id='TransactionState.transaction_cancelled'
                description='The displayed value for the TransactionState transaction_cancelled'
                defaultMessage='Cancelled'
            />
        )
    );
    static readonly TRANSACTION_COMPLETED = new TransactionState(
        TransactionStateName.TRANSACTION_COMPLETED,
        (
            <FormattedMessage
                id='TransactionState.transaction_completed'
                description='The displayed value for the TransactionState transaction_completed'
                defaultMessage='Completed'
            />
        )
    );
    static readonly TRANSACTION_COMPLETED_WITH_FAILURES = new TransactionState(
        TransactionStateName.TRANSACTION_COMPLETED_WITH_FAILURES,
        (
            <FormattedMessage
                id='TransactionState.transaction_completed_with_failures'
                description='The displayed value for the TransactionState transaction_completed_with_failures'
                defaultMessage='Completed with failures'
            />
        )
    );
    static readonly TRANSACTION_FAILED = new TransactionState(
        TransactionStateName.TRANSACTION_FAILED,
        (
            <FormattedMessage
                id='TransactionState.transaction_failed'
                description='The displayed value for the TransactionState transaction_failed'
                defaultMessage='Failed'
            />
        )
    );

    static readonly ALL = [
        TransactionState.TRANSACTION_CREATED,
        TransactionState.TRANSACTION_CANCELLED,
        TransactionState.TRANSACTION_COMPLETED,
        TransactionState.TRANSACTION_PROCESSING,
        TransactionState.TRANSACTION_COMPLETED_WITH_FAILURES,
        TransactionState.TRANSACTION_FAILED
    ];

    private constructor(public readonly value: TransactionStateName, public readonly displayName: React.ReactNode) {}

    static getByValue(action: TransactionStateName): TransactionState | undefined {
        return this.ALL.find((a) => a.value == action);
    }

    static getType(state: TransactionStateName): ClassType {
        switch (state) {
            case TransactionStateName.TRANSACTION_COMPLETED:
                return ClassType.SUCCESS;
            case TransactionStateName.TRANSACTION_CREATED:
            case TransactionStateName.TRANSACTION_PROCESSING:
                return ClassType.WARNING;
            case TransactionStateName.TRANSACTION_CANCELLED:
                return ClassType.DARK;
            case TransactionStateName.TRANSACTION_COMPLETED_WITH_FAILURES:
            case TransactionStateName.TRANSACTION_FAILED:
                return ClassType.DANGER;
        }

    }
}
