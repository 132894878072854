import { useAppSelector } from '@frontend/common';
import { UserClient } from '@frontend/user/api';
import { User } from '@frontend/user/types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { navigationStore } from '../redux/user-interface-navigation-slice';

interface ViewProps {
    user: User | null;
    spotTitle: string | null;
}

const useDefaultTitle = (): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [user, changeUser] = useState<User | null>(null);
    const [spotTitle, changeSpotTitle] = useState<string | null>(null);

    useEffect(() => {
        const title = navigationState.iot?.display;
        if (title && typeof title === 'string') changeSpotTitle(title);
    }, []);

    useEffect(() => {
        if (navigationState.user) UserClient.fetchUser(navigationState.user.accountId, navigationState.user.id).then(changeUser);
    }, [navigationState.user]);

    return {
        user,
        spotTitle
    };
};

export default useDefaultTitle;
