import { ModuleLayoutItemProps } from './module-layout-item.component';

interface ViewProps {
    style: React.CSSProperties;
    label?: string;
    divClassName: string;
    spanClassName: string;
}

export const moduleLayoutItemDivClass = 'border border-secondary';
export const moduleLayoutItemSpanClass = '';
const useModuleLayoutItem = (props: ModuleLayoutItemProps): ViewProps => {
    const divClassName = props.styles?.div
        ? typeof props.styles?.div === 'function'
            ? props.styles?.div(props.module, props.slotRendering)
            : props.styles?.div
        : moduleLayoutItemDivClass;
    const spanClassName = props.styles?.span
        ? typeof props.styles?.span === 'function'
            ? props.styles?.span(props.module, props.slotRendering)
            : props.styles?.span
        : moduleLayoutItemSpanClass;
    const label = typeof props.label === 'function' ? props.label(props.module, props.slotRendering) : props.label;
    const style: React.CSSProperties = {
        position: 'absolute',
        left: `${(100 * props.module.location.x) / props.fullWidth}%`,
        right: `${100 - (100 * (props.module.location.x + props.module.dimension.width)) / props.fullWidth}%`,
        bottom: `${(100 * props.module.location.y) / props.fullHeight}%`,
        top: `${100 - (100 * (props.module.location.y + props.module.dimension.height)) / props.fullHeight}%`
    };

    return {
        style,
        label,
        divClassName,
        spanClassName
    };
};

export default useModuleLayoutItem;
