import { useEffect, useState } from 'react';

import useActivity from '../useActivity';
import { IdleTimerProps } from './idle-timer.component';

interface ViewProps {
    almostInActive: boolean;
    timeUntilInactive: number;
}
const timeUntilIdleSeconds = process.env['NX_PUBLIC_BUILD_ENV'] === 'development' ? 1000000 : 60;
const showSeconds = process.env['NX_PUBLIC_BUILD_ENV'] === 'development' ? 999990 : 45;
const useIdleTimer = (props: IdleTimerProps): ViewProps => {
    const [tickDate, changeTickDate] = useState(Date.now());
    const lastActivityDate = useActivity(props.enabled);

    const inActive = tickDate - lastActivityDate.getTime() > timeUntilIdleSeconds * 1000;
    const almostInActive = tickDate - lastActivityDate.getTime() > showSeconds * 1000;
    const timeUntilInactive = Math.ceil((timeUntilIdleSeconds * 1000 - (tickDate - lastActivityDate.getTime())) / 1000);

    useEffect(() => {
        let timerId: number | undefined = undefined;
        if (props.enabled && props.idleCallback) {
            changeTickDate(Date.now());
            timerId = window.setInterval(() => {
                changeTickDate(Date.now());
            }, 500);
        }
        return () => {
            if (timerId !== undefined) window.clearInterval(timerId);
        };
    }, [props]);

    useEffect(() => {
        if (props.enabled && inActive && tickDate != 0) {
            changeTickDate(0);
            props.idleCallback && props.idleCallback();
        }
    }, [inActive, props.enabled, props.idleCallback]);

    return {
        almostInActive,
        timeUntilInactive
    };
};

export default useIdleTimer;
