import { EntityType } from '@frontend/common';
import { DocumentClient } from '@frontend/document/api';
import { Document } from '@frontend/document/types';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const DOCUMENT_RESOLVE_KEY = 'dcm_res';
interface DocumentStore {
    [entity_type: string]: {
        [entity_id: string]: EntityDocumentsService;
    };
}

const DocumentStoreContext = createContext<{
    store: DocumentStore;
    preLoadFiles: (entityType: EntityType, entityIds: string[]) => Promise<void>;
    resolveFile: (entityType: EntityType, entityId: string) => Promise<EntityDocumentsService>;
    getFiles: (entityType: EntityType, entityId: string) => Promise<EntityDocumentsService>;
} | null>(null);

export const DocumentStoreProvider = (props: { children: React.ReactNode }) => {
    const [store, changeStore] = useState<DocumentStore>({});
    const storeRef = useRef(store);
    const [pending, changePending] = useState<{ entityType: EntityType; entityId: string }[]>([]);
    const [timeoutSet, changeTimeoutSet] = useState<boolean>(false);
    const [timeoutFinished, changeTimeoutFinished] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            for (const entity_type in store) {
                for (const entity_id in store[entity_type]) {
                    store[entity_type][entity_id].revokeURLs();
                }
            }
        };
    }, []);

    useEffect(() => {
        storeRef.current = store;
    }, [store]);

    useEffect(() => {
        if (timeoutFinished) {
            const stored = localStorage.getItem(DOCUMENT_RESOLVE_KEY);
            if (stored != null) {
                changePending(JSON.parse(stored));
                changeTimeoutSet(false);
                changeTimeoutFinished(false);
                localStorage.removeItem(DOCUMENT_RESOLVE_KEY);
            }
        }
    }, [timeoutFinished]);

    useEffect(() => {
        if (pending.length > 0) {
            const toResolve = new Map<EntityType, string[]>();
            pending.forEach((p) => {
                if (!toResolve.has(p.entityType)) {
                    toResolve.set(p.entityType, []);
                }
                toResolve.get(p.entityType)?.push(p.entityId);
            });
            Array.from(toResolve.keys()).forEach((entityType) => {
                const entityIds = toResolve.get(entityType);
                if (entityIds) {
                    preLoadFiles(entityType, entityIds);
                }
            });
            changePending([]);
            changeTimeoutSet(false);
            changeTimeoutFinished(false);
        }
    }, [pending]);

    const updateSelf = (service: EntityDocumentsService, entity_type: EntityType, entity_id: string) => {
        changeStore((store) => {
            if (!store[entity_type]) {
                store[entity_type] = {};
            }
            store[entity_type][entity_id] = service;
            return store;
        });
    };

    const preLoadFiles = async (entityType: EntityType, entityIds: string[]): Promise<void> => {
        const storeCopy = { ...store };
        if (!storeCopy[entityType]) {
            storeCopy[entityType] = {};
        }

        const newDocuments: Document[] = (await DocumentClient.resolveDocuments(entityIds, 'entity_id')).results;
        entityIds.forEach((entityId) => {
            const documents: Document[] = newDocuments.filter((d) => d.entity_id === entityId);
            if (!storeCopy[entityType][entityId]) storeCopy[entityType][entityId] = new EntityDocumentsService(documents, entityType, entityId, updateSelf);
            else {
                storeCopy[entityType][entityId].setDocuments(documents);
            }
        });
        changeStore(storeCopy);
        return;
    };

    const resolveFile = async (entityType: EntityType, entityId: string): Promise<EntityDocumentsService> => {
        if (store[entityType] && store[entityType][entityId]) {
            return Promise.resolve(store[entityType][entityId]);
        }

        if (timeoutSet === false) {
            changeTimeoutSet(true);
            setTimeout(() => {
                changeTimeoutFinished(true);
            }, 1000);
        }
        const stored = localStorage.getItem(DOCUMENT_RESOLVE_KEY);
        if (stored == null) {
            localStorage.setItem(DOCUMENT_RESOLVE_KEY, JSON.stringify([{ entityType, entityId }]));
        } else if (!JSON.parse(stored).includes({ entityType, entityId })) {
            localStorage.setItem(DOCUMENT_RESOLVE_KEY, JSON.stringify([...JSON.parse(stored), { entityType, entityId }]));
        }

        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (storeRef.current[entityType] && storeRef.current[entityType][entityId]) {
                    resolve(storeRef.current[entityType][entityId]);
                    clearInterval(interval);
                }
            }, 500);
        });
    };

    const getFiles = async (entityType: EntityType, entityId: string): Promise<EntityDocumentsService> => {
        if (store[entityType] && store[entityType][entityId]) {
            return Promise.resolve(store[entityType][entityId]);
        }
        const documents = (await DocumentClient.fetchDocuments({ entity_type: entityType, entity_id: entityId })).results;
        const service = new EntityDocumentsService(documents, entityType, entityId, updateSelf);
        const storeCopy = { ...store, [entityType]: { ...store[entityType], [entityId]: service } };
        changeStore(storeCopy);
        return Promise.resolve(service);
    };

    return <DocumentStoreContext.Provider value={{ store, preLoadFiles, resolveFile, getFiles }}>{props.children}</DocumentStoreContext.Provider>;
};

export const useDocumentRepository = () => {
    const context = useContext(DocumentStoreContext);
    if (!context) {
        throw new Error('useDocumentRepository must be used within an DocumentStoreContext');
    }
    return context;
};

export enum DocType {
    IMAGE,
    VIDEO,
    TEXT
}

export const IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif'];
export const VIDEO_MIME_TYPES = ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/quicktime'];
export const TEXT_MIME_TYPES = ['text/csv', 'application/xml', 'application/json', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
interface DocumentInfo extends Document {
    blob?: Blob;
    url?: string;
    sasUrl?: string;
}

class EntityDocumentsService {
    private updateSelf: (service: EntityDocumentsService, entity_type: EntityType, entity_id: string) => void;
    private entityType: EntityType;
    private entityId: string;
    private documents: DocumentInfo[];
    constructor(
        documents: DocumentInfo[],
        entity_type: EntityType,
        entity_id: string,
        updateSelf: (service: EntityDocumentsService, entity_type: EntityType, entity_id: string) => void
    ) {
        this.documents = documents;
        this.entityType = entity_type;
        this.entityId = entity_id;
        this.updateSelf = updateSelf;
    }

    public getDocuments(): DocumentInfo[] {
        return this.documents;
    }

    public setDocuments(documents: Document[]) {
        const ids = documents.map((d) => d.id);
        const documentsCopy: Document[] = [...this.documents.filter((d) => ids.includes(d.id))];
        documents.forEach((newDocument) => {
            const found = documentsCopy.find((d) => d.id === newDocument.id);
            if (found) {
                if (found.update_timestamp != newDocument.update_timestamp) {
                    const index = documentsCopy.indexOf(found);
                    documentsCopy[index] = newDocument;
                }
            } else documentsCopy.push(newDocument);
        });
        this.documents = documentsCopy;
    }

    revokeURLs(): void {
        this.documents.forEach((document) => {
            if (!!document && !!document.url) {
                URL.revokeObjectURL(document.url);
            }
        });
    }
    revokeURL(documentId: string): void {
        const document = this.documents?.find((f) => f.id === documentId);
        if (!!document && !!document.url) {
            URL.revokeObjectURL(document.url);
        }
    }

    async getType(documentId: string): Promise<DocType> {
        const document = this.documents?.find((f) => f.id === documentId);
        if (!document) throw new Error('Document not found');
        if (!document.document_info) throw new Error('Document info not found');
        if (!document.document_info.mime_type) throw new Error('Document mime type not found');
        if (IMAGE_MIME_TYPES.includes(document.document_info.mime_type)) return DocType.IMAGE;
        else if (VIDEO_MIME_TYPES.includes(document.document_info.mime_type)) return DocType.VIDEO;
        else if (TEXT_MIME_TYPES.includes(document.document_info.mime_type)) return DocType.TEXT;
        throw new Error('Document mime type not supported');
    }
    async getUrl(documentId: string): Promise<string> {
        const document = this.documents?.find((f) => f.id === documentId);
        if (!document) throw new Error('Document not found');
        if (document.url) return document.url;
        else {
            const index = this.documents?.findIndex((de) => de.id === document.id);
            const result = await DocumentClient.fetchDocumentFile(document.account_id, document.id).then((res) => {
                const url = URL.createObjectURL(res);
                this.documents[index] = {
                    ...document,
                    blob: res,
                    url: url
                };
                return url;
            });
            this.updateSelf(this, this.entityType, this.entityId);
            return result;
        }
    }

    async getBlob(documentId: string): Promise<Blob> {
        const document = this.documents?.find((f) => f.id === documentId);
        if (!document) throw new Error('Document not found');
        if (document.blob) return document.blob;
        else {
            const index = this.documents?.findIndex((de) => de.id === document.id);
            const blob = await DocumentClient.fetchDocumentFile(document.account_id, document.id).then((res) => {
                this.documents[index] = {
                    ...document,
                    blob: res,
                    url: URL.createObjectURL(res)
                };
                return res;
            });
            this.updateSelf(this, this.entityType, this.entityId);
            return blob;
        }
    }

    async getSasUrl(documentId: string): Promise<string> {
        const document = this.documents?.find((f) => f.id === documentId);
        if (!document) throw new Error('Document not found');
        if (document.sasUrl) return document.sasUrl;
        else {
            const index = this.documents?.findIndex((de) => de.id === document.id);
            const result = await DocumentClient.generateDocumentSasUrl(document.account_id, document.id).then((sasUrl) => {
                this.documents[index] = {
                    ...document,
                    sasUrl: sasUrl
                };
                return sasUrl;
            });
            this.updateSelf(this, this.entityType, this.entityId);
            return result;
        }
    }
}
