import { Spinner } from '@frontend/elements';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserInterfaceProps } from '../types/user-interface';
import useOnDutyList from './on-duty-list.controller';
import { PharmacistCard } from './pharmacist-card/pharmacist-card';

export const OnDutyList = (props: UserInterfaceProps) => {
    const viewProps = useOnDutyList(props);

    if (!viewProps.pharmacistList) {
        return <Spinner />;
    }
    if (viewProps.pharmacistList.length === 0) {
        return (
            <div className='h-100'>
                <div className='d-flex h-80 p-7 flex-column align-items-center align-content-center justify-content-center align-self-center'>
                    <span className='text-center fs-1 font-weight-bolder'>
                        <FormattedMessage
                            defaultMessage='Om de apotheek met nachtwacht te kennen, bel tussen 22 en 9 uur naar: 078 05 17 33 (zonaal tarief).'
                            id='on-duty-list.header'
                        />
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className='h-100'>
            <div className='d-flex flex-column mx-3'>
                {viewProps.pharmacistList.map((pharmacist) => (
                    <PharmacistCard
                        pharmacist={pharmacist}
                        key={pharmacist.id}
                    />
                ))}
            </div>
            <div className='d-flex justify-content-center mx-3 font-weight-bolder mt-3'>
                <FormattedMessage
                    id='on-duty-list.footer'
                    defaultMessage={'This information is retrieved from {link}'}
                    values={{
                        link: 'www.apotheek.be'
                    }}
                />
            </div>
        </div>
    );
};
