import { SlotRendering } from '@frontend/slot/types';
import React from 'react';

import useLayoutItem from './layout-item.controller';

const ID = 'layout-item';
export interface LayoutItemProps {
    id?: string;

    fullWidth: number;
    fullHeight: number;
    slotRendering: SlotRendering;

    label?: string | ((slotRendering: SlotRendering) => string);
    component?: (slotRendering: SlotRendering) => React.ReactNode;
    onClick?: (slotRendering: SlotRendering) => void;
    styles?: {
        div?: string | ((slotRendering: SlotRendering) => string);
        span?: string | ((slotRendering: SlotRendering) => string);
    };
}

const LayoutItem = (props: LayoutItemProps) => {
    const viewProps = useLayoutItem(props);
    if (props.component) {
        return (
            <div
                style={viewProps.style}
                id={ID + '-' + props.id}
                onClick={viewProps.onClick}>
                {props.component(props.slotRendering)}
            </div>
        );
    }
    return (
        <div
            style={viewProps.style}
            id={ID + '-' + props.id}
            onClick={viewProps.onClick}>
            <div className={viewProps.divClassName}>
                <span className={viewProps.spanClassName}>{viewProps.label}</span>
            </div>
        </div>
    );
};

export default LayoutItem;
