import { TranslationProvider } from '@frontend/lang';
import { AccountStoreProvider, DocumentStoreProvider } from '@frontend/repository';
import { BrowserRouter as Router } from 'react-router-dom';

import Footer from './home-page/footer/footer.component';
import HomePage from './home-page/home-page.component';
import Navbar from './home-page/navbar/navbar.component';
import Initialisation from './initialisation/initialisation.component';
import Resolver from './utils/resolver';

export function App() {
    return (
        <TranslationProvider>
            <DocumentStoreProvider>
                <Router>
                    <AccountStoreProvider>
                        <Resolver>
                            <div
                                className='app-content d-flex flex-column no-select'
                                id='app-content'>
                                <Navbar />
                                <Initialisation>
                                    <HomePage />
                                </Initialisation>
                                <Footer />
                            </div>
                        </Resolver>
                    </AccountStoreProvider>
                </Router>
            </DocumentStoreProvider>
        </TranslationProvider>
    );
}

export default App;
