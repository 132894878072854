import { useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { PackageClient, PackageWorkflowClient } from '@frontend/package/api';
import { Package, PackageStateName, PackageType } from '@frontend/package/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { navigationStore } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ViewProps {
    slot: Slot | null;
    isSlotOpening: boolean;
    changeIsSlotOpening: React.Dispatch<React.SetStateAction<boolean>>;
    isSlotOpen: boolean;
    processPackage: () => void;
}

const useOnDutyCustomerPickUp = (): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [isSlotOpening, changeIsSlotOpening] = useState<boolean>(false);
    const [isSlotOpen, changeIsSlotOpen] = useState<boolean>(false);
    const [_package, changePackage] = useState<Package | null>(null);

    useEffect(() => {
        const spotId = navigationState.iot?.spot_id;
        SlotClient.fetchSlots({ type: SlotType.PASS_THROUGH, spot_id: spotId as string | undefined }).then((res) => {
            if (res.results.length === 1) {
                changeSlot(res.results[0]);
            }
        });

        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_ON_DUTY_CUSTOMER_PICK_UP });
            PackageClient.fetchPackages({
                type: PackageType.PASS_THROUGH,
                spot_id: spotId as string | undefined,
                state: PackageStateName.PACKAGE_PASS_THROUGH_PENDING
            }).then((packages) => {
                if (packages.results.length === 1) {
                    changePackage(packages.results[0]);
                }
            });
        }
    }, []);

    function processPackage() {
        if (_package) {
            PackageWorkflowClient.updatePackageState(_package.account_id, _package.transaction_id, _package.id, PackageStateName.PACKAGE_PASS_THROUGH_PENDING);
        }
    }

    return {
        slot,
        changeIsSlotOpening,
        isSlotOpening,
        isSlotOpen,
        processPackage
    };
};

export default useOnDutyCustomerPickUp;
