import { Spinner } from '@frontend/elements';
import { Module } from '@frontend/module/types';
import { Slot } from '@frontend/slot/types';
import { Spot } from '@frontend/spot/types';
import React from 'react';

import LayoutRenderer from './layout-renderer/layout-renderer.component';
import useLayout from './layout.controller';

const ID = 'layout';

/**
 * Provide either spot or modules or slots.
 */
export interface LayoutProps {
    id?: string;
    spot?: Spot;
    modules?: Module[];
    slots?: Slot[];

    renderingProps?: Omit<React.ComponentProps<typeof LayoutRenderer>, 'id' | 'scale' | 'modules' | 'slotRendering' | 'containerInfo'>;
}

export const Layout = (props: LayoutProps) => {
    const viewProps = useLayout(props);

    return (
        <div
            id={ID}
            className='w-100 h-100'>
            {viewProps.slotRendering === null || viewProps.modules === null ? (
                <Spinner />
            ) : (
                <LayoutRenderer
                    id={props.id}
                    modules={viewProps.modules}
                    slotRendering={viewProps.slotRendering}
                    containerInfo={viewProps.containerInfo}
                    {...props.renderingProps}
                />
            )}
        </div>
    );
};

export default Layout;
