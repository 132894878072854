import { ModuleClient } from '@frontend/module/api';
import { Module } from '@frontend/module/types';
import { SlotRenderingClient } from '@frontend/slot/api';
import { SlotRendering } from '@frontend/slot/types';
import { useEffect, useState } from 'react';

import { LayoutProps } from './layout.component';

interface ViewProps {
    modules: Module[] | null;
    slotRendering: Map<string, SlotRendering[]> | null;
    containerInfo: { maxX: number; maxY: number; aspectRatio: number };
}

const useLayout = (props: LayoutProps): ViewProps => {
    const [modules, changeModules] = useState<Module[] | null>(props.modules || null);
    const [slotRendering, changeSlotRendering] = useState<Map<string, SlotRendering[]> | null>(null);
    const [containerInfo, changeContainerInfo] = useState<{ maxX: number; maxY: number; aspectRatio: number }>({ maxX: 0, maxY: 0, aspectRatio: 1 });

    useEffect(() => {
        if (props.spot) {
            ModuleClient.fetchModules({ spot_id: props.spot.id }).then((res) => changeModules(res.results));
        }
    }, [props.spot]);

    useEffect(() => {
        if (modules) {
            let maxY = 0;
            let maxX = 0;
            if (modules.length === 1) {
                maxX = modules[0].dimension.width;
                maxY = modules[0].dimension.height;
            } else
                modules.forEach((module) => {
                    if (module.location.x + module.dimension.width > maxX) maxX = module.location.x + module.dimension.width;
                    if (module.location.y + module.dimension.height > maxY) maxY = module.location.y + module.dimension.height;
                });

            const renderingMap: Map<string, SlotRendering[]> = new Map();
            const promises = modules.map((module) => {
                return SlotRenderingClient.fetchSlotRendering(module.account_id, module.spot_id, module.id).then((res) => {
                    return {
                        key: module.id,
                        value: res.results
                    };
                });
            });

            Promise.all(promises).then((results) => {
                results.map((result) => {
                    renderingMap.set(result.key, result.value);
                });
                changeSlotRendering(renderingMap);

                if (modules.length === 1) {
                    Array.from(renderingMap.values())
                        .flat()
                        .forEach((slotRendering) => {
                            if (slotRendering.location.x + slotRendering.dimension.width > maxX)
                                maxX = slotRendering.location.x + slotRendering.dimension.width;
                            if (slotRendering.location.y + slotRendering.dimension.height > maxY)
                                maxY = slotRendering.location.y + slotRendering.dimension.height;
                        });
                } else {
                    Array.from(renderingMap.values())
                        .flat()
                        .forEach((slotRendering) => {
                            if (slotRendering.absolute.x + slotRendering.dimension.width > maxX)
                                maxX = slotRendering.absolute.x + slotRendering.dimension.width;
                            if (slotRendering.absolute.y + slotRendering.dimension.height > maxY)
                                maxY = slotRendering.absolute.y + slotRendering.dimension.height;
                        });
                }
                changeContainerInfo({ maxX, maxY, aspectRatio: maxY / maxX });
            });
        }
    }, [modules]);

    return {
        modules,
        slotRendering,
        containerInfo
    };
};

export default useLayout;
