import { useAppSelector } from '@frontend/common';
import { IoTClient } from '@frontend/iot/api';
import { IoTStateName } from '@frontend/iot/types';
import { SlotClient } from '@frontend/slot/api';
import { Slot, SlotType } from '@frontend/slot/types';
import { navigationStore } from '@frontend/terminal/shared';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ViewProps {
    slot: Slot | null;
    isSlotOpening: boolean;
    changeIsSlotOpening: React.Dispatch<React.SetStateAction<boolean>>;
}

const useOnDutyPharmacistPickUp = (): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [slot, changeSlot] = useState<Slot | null>(null);
    const [isSlotOpening, changeIsSlotOpening] = useState<boolean>(false);

    useEffect(() => {
        const spotId = navigationState.iot?.spot_id;
        SlotClient.fetchSlots({ type: SlotType.PASS_THROUGH, spot_id: spotId as string | undefined }).then((res) => {
            if (res.results.length === 1) {
                changeSlot(res.results[0]);
            }
        });

        if (navigationState.iot) {
            IoTClient.patchIoT(navigationState.iot.account_id, navigationState.iot.id, { state: IoTStateName.IOT_ON_DUTY_PHARMACIST_PICK_UP }).catch(
                console.error
            );
        }
    }, []);

    return {
        slot,
        changeIsSlotOpening,
        isSlotOpening
    };
};

export default useOnDutyPharmacistPickUp;
