import { authorizationStore } from '@frontend/authorization';
import { packageStore } from '@frontend/package/redux';
import { productCategoryStore } from '@frontend/product-category/redux';
import { productStore } from '@frontend/product/redux';
import { resolveStore } from '@frontend/repository';
import { navigationStore } from '@frontend/terminal/shared';
import { userInterfaceButtonStore } from '@frontend/user-interface-button/redux';
import { userInterfaceStore } from '@frontend/user-interface/redux';
import { workflowStepStore } from '@frontend/workflow-step/redux';
import { workflowSharedStore, workflowStore, workflowVariablesStore } from '@frontend/workflow/redux';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        ...workflowStore,
        ...workflowStepStore,
        ...userInterfaceStore,
        ...userInterfaceButtonStore,
        ...workflowSharedStore,
        ...productCategoryStore,
        ...productStore,
        ...packageStore,
        ...resolveStore,
        ...workflowVariablesStore,
        ...authorizationStore,
        //terminal states
        ...navigationStore
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
