import { useEffect, useRef, useState } from 'react';

import { LayoutRendererProps } from './layout-renderer.component';

interface ViewProps {
    layoutBox: React.RefObject<HTMLDivElement>;
    containerStyle: React.CSSProperties;
}

const useLayoutRenderer = (props: LayoutRendererProps): ViewProps => {
    const layoutBox = useRef<HTMLDivElement>(null);
    const [containerStyle, changeContainerStyle] = useState<React.CSSProperties>({});

    useEffect(() => {
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        const currentLayoutBoxWidth = layoutBox && layoutBox.current ? layoutBox.current.clientWidth : null;
        const currentLayoutBoxHeight = layoutBox && layoutBox.current ? layoutBox.current.clientHeight : null;
        const currentLayoutBoxAspectratio =
            currentLayoutBoxWidth !== null && currentLayoutBoxHeight !== null ? currentLayoutBoxHeight / currentLayoutBoxWidth : null;

        if (currentLayoutBoxAspectratio !== null && currentLayoutBoxHeight !== null && currentLayoutBoxWidth !== null) {
            if (props.containerInfo.maxX > props.containerInfo.maxY) {
                if (currentLayoutBoxAspectratio < props.containerInfo.aspectRatio) {
                    const Y = viewportHeight - (viewportHeight - currentLayoutBoxHeight);
                    const YP = (Y / viewportHeight) * 100;
                    const X = (props.containerInfo.maxX / props.containerInfo.maxY) * Y;
                    const XP = (X / viewportWidth) * 100;
                    changeContainerStyle({ height: Math.floor(YP) + 'vh', width: Math.floor(XP) + 'vw' });
                } else {
                    const X = viewportWidth - (viewportWidth - currentLayoutBoxWidth);
                    const XP = (X / viewportWidth) * 100;
                    const Y = (props.containerInfo.maxY / props.containerInfo.maxX) * X;
                    const YP = (Y / viewportHeight) * 100;
                    changeContainerStyle({ width: Math.floor(XP) + 'vw', height: Math.floor(YP) + 'vh' });
                }
            } else {
                if (currentLayoutBoxAspectratio > props.containerInfo.aspectRatio) {
                    const X = viewportWidth - (viewportWidth - currentLayoutBoxWidth);
                    const XP = (X / viewportWidth) * 100;
                    const Y = (props.containerInfo.maxY / props.containerInfo.maxX) * X;
                    const YP = (Y / viewportHeight) * 100;
                    changeContainerStyle({ width: Math.floor(XP) + 'vw', height: Math.floor(YP) + 'vh' });
                } else {
                    const Y = viewportHeight - (viewportHeight - currentLayoutBoxHeight);
                    const YP = (Y / viewportHeight) * 100;
                    const X = (props.containerInfo.maxX / props.containerInfo.maxY) * Y;
                    const XP = (X / viewportWidth) * 100;
                    changeContainerStyle({ height: Math.floor(YP) + 'vh', width: Math.floor(XP) + 'vw' });
                }
            }
        }
    }, [layoutBox.current, layoutBox, window.innerHeight, window.innerWidth, props.containerInfo]);

    return {
        layoutBox,
        containerStyle
    };
};

export default useLayoutRenderer;
