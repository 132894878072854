import { Module } from '@frontend/module/types';
import { SlotRendering } from '@frontend/slot/types';
import React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import useLayoutRenderer from './layout-renderer.controller';
import ModuleLayoutItem from './module-layout-item/module-layout-item.component';

const ID = 'layout-renderer';
export interface LayoutRendererProps {
    id?: string;
    modules: Module[];
    slotRendering: Map<string, SlotRendering[]>;
    containerInfo: {
        maxX: number;
        maxY: number;
        aspectRatio: number;
    };

    moduleRenderingProps?: Omit<React.ComponentProps<typeof ModuleLayoutItem>, 'id' | 'module' | 'slotRendering' | 'fullWidth' | 'fullHeight'>;
}

const LayoutRenderer = (props: LayoutRendererProps) => {
    const viewProps = useLayoutRenderer(props);

    return (
        <div
            id={ID}
            className='w-100 h-100'
            ref={viewProps.layoutBox}>
            <TransformWrapper
                centerOnInit
                initialScale={1}
                minScale={1}>
                <TransformComponent wrapperClass='w-100 h-100'>
                    <div style={viewProps.containerStyle}>
                        {props.modules.length === 1 ? (
                            <ModuleLayoutItem
                                key={props.modules[0].id + 'SPL-module-item'}
                                id={props.id}
                                module={{
                                    ...props.modules[0],
                                    location: { x: 0, y: 0, z: 0, rotation: 0 }
                                }}
                                slotRendering={props.slotRendering.get(props.modules[0].id) || []}
                                fullHeight={props.containerInfo.maxY}
                                fullWidth={props.containerInfo.maxX}
                                {...props.moduleRenderingProps}
                            />
                        ) : (
                            props.modules.map((module) => {
                                return (
                                    <ModuleLayoutItem
                                        key={module.id + 'SPL-module-item'}
                                        id={props.id}
                                        module={module}
                                        slotRendering={props.slotRendering.get(module.id) || []}
                                        fullHeight={props.containerInfo.maxY}
                                        fullWidth={props.containerInfo.maxX}
                                        {...props.moduleRenderingProps}
                                    />
                                );
                            })
                        )}
                        {}
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
};

export default LayoutRenderer;
