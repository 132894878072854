import { Logger } from '@frontend/Logger';
import { DriverAuthenticationClient, DriverAuthenticationManager } from '@frontend/authentication-v2';
import { EntityType } from '@frontend/common';
import { IoTClient, IoTWorkflowClient } from '@frontend/iot/api';
import { IoTStateName, IoTTriggerName } from '@frontend/iot/types';
import { MqttBroker, PubSubMessage } from '@frontend/pub-sub';
import { changeView, endUserSession, setUserHome, startUserSession, updateIoTState, useHeartbeat } from '@frontend/terminal/shared';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/store';

interface ViewProps {
    isLoading: boolean;
    view: string | null;
    idleTimerEnabled: boolean;
    idleTimerCallback: () => void;
}

const useHomePage = (): ViewProps => {
    useHeartbeat();
    const dispatch = useAppDispatch();
    const navigationState = useAppSelector((state) => state.navigation);

    const callback = (message: string, data: any) => {
        if (message === 'show_user_interface') onShowUserInterface(data);
        if (message === 'notify') onNotify(data);
        if (message === 'iot_updated') onIotUpdated(data);
    };

    const onShowUserInterface = (payload: PubSubMessage) => {
        const { user_interface_id, entity_id, entity_type } = payload.data as {
            user_interface_id: string;
            entity_id?: string;
            entity_type?: EntityType;
        };

        if (entity_type === EntityType.USER && entity_id) {
            Logger.log('User id provided in last event: ', {}, entity_id);
            if (navigationState.user?.id !== entity_id && navigationState.provision) {
                Logger.log('Authorizing user: ', {}, entity_id, navigationState.provision, navigationState.cache);
                DriverAuthenticationManager.getInstance()
                    .waitForToken()
                    .then((token) => {
                        DriverAuthenticationClient.authorizeUser(payload.account_id, navigationState.iot!.id, entity_id!, token)
                            .then((result) => {
                                dispatch(startUserSession({ id: entity_id!, accountId: payload.account_id, auth: result }));
                                dispatch(setUserHome(user_interface_id));
                            })
                            .then(() => {
                                dispatch(changeView({ view: user_interface_id, cache: payload }));
                            });
                    });
                return;
            }
        }

        Logger.log('No authorization possible', {}, payload, navigationState);
        dispatch(changeView({ view: user_interface_id, cache: payload }));
        const defaultId = navigationState.iot?.default_user_interface_id;
        if (defaultId && defaultId === user_interface_id) {
            dispatch(updateIoTState({ iot_state: IoTStateName.IOT_HOME }));
        }
    };

    const onNotify = ({ account_id, iot_id, data }: PubSubMessage) => {
        const { trigger } = data as { trigger?: IoTTriggerName };
        if (trigger) IoTWorkflowClient.postIoTTrigger(account_id, iot_id, { trigger });
    };

    const onIotUpdated = (payload: PubSubMessage) => {
        const { id, state } = payload as unknown as { id: string; state: IoTStateName };
        if (id && state && navigationState.iot && navigationState.iot.id === id) {
            dispatch(updateIoTState({ iot_state: state }));
        }
    };

    useEffect(() => {
        if (navigationState.iot) {
            MqttBroker.getInstance().subscribe('HOMEPAGE_IOT_LISTENER_IOT', 'iots/' + navigationState.iot.id + '/' + 'iot', callback);
            MqttBroker.getInstance().subscribe('HOMEPAGE_DEVICE_LISTENER', 'iots/' + navigationState.iot.id + '/' + 'sensor', callback);
            MqttBroker.getInstance().subscribe('HOMEPAGE_USER_LISTENER', 'iots/' + navigationState.iot.id + '/' + 'user', callback);
        }
        return () => {
            if (navigationState.iot) {
                MqttBroker.getInstance().unSubscribe('HOMEPAGE_IOT_LISTENER_IOT', 'iots/' + navigationState.iot.id + '/' + 'iot');
                MqttBroker.getInstance().unSubscribe('HOMEPAGE_DEVICE_LISTENER', 'iots/' + navigationState.iot.id + '/' + 'sensor');
                MqttBroker.getInstance().unSubscribe('HOMEPAGE_USER_LISTENER', 'iots/' + navigationState.iot.id + '/' + 'user');
            }
        };
    }, []);

    useEffect(() => {
        if (navigationState.iot && navigationState.current == null)
            IoTClient.patchIoT(navigationState.iot!.account_id, navigationState.iot!.id, { state: IoTStateName.IOT_HOME });
    }, [navigationState.current]);

    const idleTimerCallback = () => {
        dispatch(endUserSession());
        dispatch(changeView(null));
    };

    return {
        isLoading: (navigationState.current || navigationState.root) == null,
        view: navigationState.current || navigationState.root,
        idleTimerEnabled: navigationState.user != null || navigationState.current != null,
        idleTimerCallback
    };
};

export default useHomePage;
