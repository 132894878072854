import { useAccount } from '@frontend/account/utils';
import { ApiQueryParams } from '@frontend/api-utils';
import { Spinner } from '@frontend/authorization';
import { ObjectSelectInput } from '@frontend/form';
import { IoTClient } from '@frontend/iot/api';
import { IoT, IoTQueryParams } from '@frontend/iot/types';
import React, { useState } from 'react';

const ID = 'iot-select';
export interface IoTSelectProps {
    id?: string;
    label?: React.ReactNode;
    required?: boolean;
    submitted: boolean;
    onChange?: (newValue: string | null) => void;
    onChangeObject?: (newValue: IoT | null) => void;
    value?: string | null;
    className?: string;
    disabled?: boolean;
    useConditionedStyling?: boolean;
    isClearable?: boolean;
    allowURLChange?: boolean;
    createEnabled?: boolean;
    queryParams?: ApiQueryParams<IoTQueryParams>;
    hideOnOneOption?: boolean;
}

export const IoTSelect = (props: IoTSelectProps) => {
    const { currentAccount } = useAccount();
    const [v, cV] = useState<IoT | null>(null);

    if (!currentAccount) return <Spinner />;
    return (
        <ObjectSelectInput<IoT, IoTQueryParams>
            id={`${ID}${props.id && '-' + props.id}`}
            label={props.label || 'IoT'}
            submitted={false}
            className={props.className}
            onChange={(v) => {
                props.onChange && props.onChange(v?.id || null);
                props.onChangeObject && props.onChangeObject(v);
                cV(v);
            }}
            isClearable={props.isClearable}
            required={props.required}
            queryParams={props.queryParams}
            value={v}
            idValue={props.value || v?.id}
            fetch={(props) => IoTClient.fetchIoTs({ ...props, account_id: currentAccount })}
            itemLabel={(iot) => iot.name}
            useQueryParam={
                props.allowURLChange
                    ? {
                        param: 'iot',
                        fetch: (pathParam) => IoTClient.fetchIoT(currentAccount!, pathParam)
                    }
                    : undefined
            }
            hideOnOneOption={props.hideOnOneOption}
            useConditionedStyling={props.useConditionedStyling}
        />
    );
};
