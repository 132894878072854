import { useAppSelector } from '@frontend/common';
import { ContactClient } from '@frontend/contact/api';
import { Contact } from '@frontend/contact/types';
import { ContactGroupClient } from '@frontend/group/api';
import { navigationStore } from '@frontend/terminal/shared';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ContactListProps } from './contact-list.component';

interface ViewProps {
    contacts: Contact[];
    isLoading: boolean;
}

const useContactList = (props: ContactListProps): ViewProps => {
    const navigationState = useAppSelector(useSelector, navigationStore);
    const [contacts, changeContacts] = useState<Contact[]>([]);
    const [isLoading, changeIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (props.userInterface.data?.receiverGroup && navigationState.iot) {
            ContactGroupClient.fetchContactGroup(navigationState.iot.account_id, props.userInterface.data.receiverGroup).then((group) => {
                ContactGroupClient.fetchContactGroupMembers({ group_id: group.id }).then((members) => {
                    ContactClient.resolveContacts(members.results.map((m) => m.entity_id)).then((contacts) => {
                        changeContacts(contacts.results);
                        changeIsLoading(false);
                    });
                });
            });
        } else {
            ContactClient.fetchContacts().then((res) => {
                changeContacts(res.results);
                changeIsLoading(false);
            });
        }
    }, []);

    return {
        contacts,
        isLoading
    };
};

export default useContactList;
