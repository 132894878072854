import { useEffect, useState } from 'react';

export const useActivity = (active = true) => {
    const [lastActivity, changeLastActivity] = useState(new Date());
    const handler = () => changeLastActivity(new Date());

    useEffect(() => {
        if (active) {
            handler();
            addHandlers();
        } else {
            removeHandlers();
        }
        return () => {
            removeHandlers();
        };
    }, [active]);

    const addHandlers = () => {
        //mouse events
        window.addEventListener('mousemove', handler);
        window.addEventListener('mousedown', handler);
        window.addEventListener('wheel', handler);
        //keyboard events
        window.addEventListener('keydown', handler);
        //mobile events
        window.addEventListener('touchstart', handler);
    };

    const removeHandlers = () => {
        window.removeEventListener('mousemove', handler);
        window.removeEventListener('mousedown', handler);
        window.removeEventListener('wheel', handler);
        window.removeEventListener('keydown', handler);
        window.removeEventListener('touchstart', handler);
    };

    return lastActivity;
};

export default useActivity;

/**
 * Function used to reset idle timer.
 */
export function triggerActivity() {
    window.dispatchEvent(new Event('mousemove'));
}
