import { LanguageSelect } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';

import logo from '../../../assets/images/logo_telloport_horizontal.png';
import useNavbar from './navbar.controller';

const ID = 'navbar';
export interface NavbarProps {}

const Navbar = (props: NavbarProps) => {
    const viewProps = useNavbar(props);

    return (
        <div id={ID}>
            <div className='d-flex flex-row justify-content-between align-items-center mx-4 mb-4 mt-3'>
                <img
                    className='img-fluid w-30'
                    alt='Logo'
                    src={logo}
                />
                <span></span>
                <div
                    className='btn-group'
                    role='group'>
                    {viewProps.showHistory && (
                        <button
                            onClick={viewProps.onHistoryCommand}
                            className='btn btn-light btn-rounded mb-0'>
                            <ICONS.BUTTON.BACK size={50} />
                        </button>
                    )}
                    {viewProps.showHome && (
                        <button
                            onClick={viewProps.onHome}
                            className='btn btn-light btn-rounded mb-0'>
                            <ICONS.BUTTON.HOME size={50} />
                        </button>
                    )}
                    <LanguageSelect className='fs-5 mb-0' />
                    {viewProps.showLogout && (
                        <button
                            onClick={viewProps.onLogout}
                            className='btn btn-light btn-rounded d-flex align-content-center align-items-center mb-0'>
                            <ICONS.BUTTON.LOGOUT
                                className='text-danger'
                                size={50}
                            />
                            <p className='text-5xl text-danger font-weight-bolder pb-0 mb-0 ms-1'>{CommonMessage.BUTTONS.EXIT}</p>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
