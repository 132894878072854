import { ClassType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum PackageStateName {
    PACKAGE_CREATED = 'package_created',
    PACKAGE_DROP_OFF_PENDING = 'package_drop_off_pending',
    PACKAGE_DROP_OFF_STARTED = 'package_drop_off_started',
    PACKAGE_DROP_OFF_DONE = 'package_drop_off_done',
    PACKAGE_PICK_UP_PENDING = 'package_pick_up_pending',
    PACKAGE_PICK_UP_STARTED = 'package_pick_up_started',
    PACKAGE_PICK_UP_DONE = 'package_pick_up_done',
    PACKAGE_PASS_THROUGH_STARTED = 'package_pass_through_started',
    PACKAGE_PASS_THROUGH_DONE = 'package_pass_through_done',
    PACKAGE_PASS_THROUGH_PENDING = 'package_pass_through_pending'
}

export class PackageState {
    static readonly PACKAGE_CREATED = new PackageState(
        PackageStateName.PACKAGE_CREATED,
        (
            <FormattedMessage
                id='packageState.created'
                description='package state created'
                defaultMessage='Created'
            />
        )
    );
    static readonly PACKAGE_DROP_OFF_PENDING = new PackageState(
        PackageStateName.PACKAGE_DROP_OFF_PENDING,
        (
            <FormattedMessage
                id='packageState.dropoff_pending'
                description='package state dropoff pending'
                defaultMessage='DropOff pending'
            />
        )
    );
    static readonly PACKAGE_DROP_OFF_STARTED = new PackageState(
        PackageStateName.PACKAGE_DROP_OFF_STARTED,
        (
            <FormattedMessage
                id='packageState.dropoff_started'
                description='package state dropoff started'
                defaultMessage='DropOff started'
            />
        )
    );
    static readonly PACKAGE_DROP_OFF_DONE = new PackageState(
        PackageStateName.PACKAGE_DROP_OFF_DONE,
        (
            <FormattedMessage
                id='packageState.dropoff_done'
                description='package state dropoff done'
                defaultMessage='DropOff done'
            />
        )
    );
    static readonly PACKAGE_PICK_UP_PENDING = new PackageState(
        PackageStateName.PACKAGE_PICK_UP_PENDING,
        (
            <FormattedMessage
                id='packageState.pickup_pending'
                description='package state pickup pending'
                defaultMessage='PickUp pending'
            />
        )
    );
    static readonly PACKAGE_PICK_UP_STARTED = new PackageState(
        PackageStateName.PACKAGE_PICK_UP_STARTED,
        (
            <FormattedMessage
                id='packageState.pickup_started'
                description='package state pickup started'
                defaultMessage='PickUp started'
            />
        )
    );
    static readonly PACKAGE_PICK_UP_DONE = new PackageState(
        PackageStateName.PACKAGE_PICK_UP_DONE,
        (
            <FormattedMessage
                id='packageState.pickup_done'
                description='package state pickup done'
                defaultMessage='PickUp done'
            />
        )
    );
    static readonly PACKAGE_PASS_THROUGH_STARTED = new PackageState(
        PackageStateName.PACKAGE_PASS_THROUGH_STARTED,
        (
            <FormattedMessage
                id='packageState.pass_through_started'
                description='package state pass through started'
                defaultMessage='Pass Through started'
            />
        )
    );
    static readonly PACKAGE_PASS_THROUGH_DONE = new PackageState(
        PackageStateName.PACKAGE_PASS_THROUGH_DONE,
        (
            <FormattedMessage
                id='packageState.pass_through_done'
                description='package state pass through done'
                defaultMessage='Pass Through done'
            />
        )
    );
    static readonly PACKAGE_PASS_THROUGH_PENDING = new PackageState(
        PackageStateName.PACKAGE_PASS_THROUGH_PENDING,
        (
            <FormattedMessage
                id='packageState.pass_through_pending'
                description='package state pass through pending'
                defaultMessage='Pass Through pending'
            />
        )
    );

    static readonly ALL = [
        PackageState.PACKAGE_CREATED,
        PackageState.PACKAGE_DROP_OFF_PENDING,
        PackageState.PACKAGE_DROP_OFF_STARTED,
        PackageState.PACKAGE_DROP_OFF_DONE,
        PackageState.PACKAGE_PICK_UP_PENDING,
        PackageState.PACKAGE_PICK_UP_STARTED,
        PackageState.PACKAGE_PICK_UP_DONE,
        PackageState.PACKAGE_PASS_THROUGH_STARTED,
        PackageState.PACKAGE_PASS_THROUGH_DONE,
        PackageState.PACKAGE_PASS_THROUGH_PENDING
    ];

    constructor(public value: PackageStateName, public displayName: React.ReactNode) {}

    static getByValue(action: PackageStateName): PackageState | undefined {
        return this.ALL.find((a) => a.value == action);
    }

    static getType(action: PackageStateName): ClassType {
        switch (action) {
            case PackageStateName.PACKAGE_DROP_OFF_STARTED:
            case PackageStateName.PACKAGE_PICK_UP_STARTED:
                return ClassType.WARNING;
            case PackageStateName.PACKAGE_PICK_UP_PENDING:
            case PackageStateName.PACKAGE_DROP_OFF_PENDING:
                return ClassType.SECONDARY;
            case PackageStateName.PACKAGE_DROP_OFF_DONE:
            case PackageStateName.PACKAGE_PICK_UP_DONE:
                return ClassType.SUCCESS;
            case PackageStateName.PACKAGE_CREATED:
                return ClassType.INFO;
            default:
                return ClassType.DANGER;
        }
    }
}
